import HeroSection from "../components/hero/hero";
import Card from "components/card/card";
import AppDetails from "../components/appDetails/appDetails";
import BarllenoEventSection from "../components/barllenoEvents/barllenoEvent";
import OnTrade from "app/home/components/onTrade/onTrade";
import TicketsSection from "../components/tickets/tickets";
import GoSection from "../components/go/go";
import BlogSection from "../components/blog/blog";

import { Button } from "components/ui/button";
import { Divider } from "components/divider/divider";

/* IMAGES */
import targetIcon from "assets/icons/target.svg";
import rocketIcon from "assets/icons/rocket.svg";
import binocularsIcon from "assets/icons/binoculars.svg";
import diamondIcon from "assets/icons/diamond.svg";
import useSize from "hooks/useScreenSize";
import { useEffect, useState } from "react";
import Layout from "components/layout/layout";

const info = [
  {
    title: "Nuestro objetivo",
    text: "Ser líderes en el mercado brindando una experiencia personalizada para individuos y empresas, mejorando la calidad de vida y promoviendo el éxito sostenible mediante la innovación, excelencia en servicio al cliente y expansión de productos y servicios.",
    img: targetIcon,
  },
  {
    title: "Nuestra misión",
    text: "Ofrecer experiencias inolvidables en bebidas, mixología y tecnología, siendo líderes del mercado y mejorando la calidad de vida de nuestros clientes a través de la innovación, la excelencia y la expansión de nuestro ecosistema.",
    img: rocketIcon,
  },
  {
    title: "Nuestra visión",
    text: "Ser el destino líder en bebidas, mixología y tecnología, inspirando a través de experiencias personalizadas y una innovación continua. Nos guiamos por la excelencia en todo lo que hacemos, marcando el camino hacia un futuro lleno de creatividad y calidad",
    img: binocularsIcon,
  },
  {
    title: "Nuestros valores",
    text: "Lorem ipsum dolor sit amet consectetur. Sit pellentesque turpis fermentum non. Sem porta bibendum leo sit tortor porttitor. Lorem sem diam faucibus lacus vel lorem.",
    options: [
      {
        title: "Innovación:",
        text: "Buscamos constantemente mejorar mediante tecnología y métodos innovadores.",
      },
      {
        title: "Excelencia:",
        text: "Nos comprometemos con estándares superiores en todas nuestras entregas.",
      },
      {
        title: "Colaboración:",
        text: "Valoramos el trabajo en equipo para alcanzar objetivos comunes.",
      },
      {
        title: "Responsabilidad Social:",
        text: "Nos esforzamos por ser agentes de cambio positivo en nuestras comunidades.",
      },
      {
        title: "Servicio al Cliente:",
        text: "Nos dedicamos a proporcionar experiencias memorables y satisfactorias.",
      },
      {
        title: "Integridad:",
        text: "Actuamos con honestidad y transparencia en todas nuestras operaciones.",
      },
    ],
    img: diamondIcon,
  },
];

export const HomePage = () => {
  const [windowsize, setWindowsize] = useState(useSize());
  useEffect(() => {
    console.log(windowsize);
  }, [windowsize]);
  return (
    <Layout>
      <HeroSection></HeroSection>

      <div
        className={`grid sm:grid-cols-2 grid-cols-1 gap-[20px] px-[5%] mt-10 max-w-[1500px] md:mx-auto`}>
        {info.map((inf, index) => (
          <Card
            key={index}
            title={inf.title}
            img={inf.img}
            text={inf.text} options={inf.options ?? undefined}
            index={index}></Card>
        ))}
      </div>
      <div className="flex justify-center mt-10">
        <Button className="text-white px-7">Más Información</Button>
      </div>

      <AppDetails></AppDetails>

      <BarllenoEventSection></BarllenoEventSection>

      <OnTrade></OnTrade>

      <TicketsSection></TicketsSection>

      <Divider></Divider>
      <GoSection></GoSection>
      <BlogSection></BlogSection>
    </Layout>
  );
};
