import { Button } from "components/ui/button";

export default function HeroSection() {
  return (
    <div className="flex gap-[20px] px-[5%] flex-col sm:flex-row pt-[85px] max-w-[1500px] md:mx-auto">
      <div className="w-full sm:w-[60%] md:w-[40%] flex text-start">
        <img
          src={require("assets/hero.png")}
          className="h-[150px] sm:h-[100%] rounded-2xl w-full object-cover"
          alt="HeroImage"></img>
      </div>

      <div className="w-full sm:w-[60%] flex flex-col justify-between gap-5">
        <div>
          <h1 className="text-2xl md:text-3xl font-light">
            Conocemos el mundo de las bebidas.
          </h1>
          <h2 className="text-xl md:text-3xl font-bold">
            Creamos experiencias inolvidables en conjunto con las bebidas,
            mixología y tecnología
          </h2>
          <p className="mt-5">
            <span className="font-bold">Descubre Barlleno LATAM:</span> excelencia, comodidad y
            tecnología para una experiencia incomparable de bebidas y
            experiencia en mixología. Únete y vive la calidad de Barlleno en
            latinoamérica
          </p>
        </div>

        <Button className="bg-primary text-white w-full sm:w-max px-[40px]">
          Compra Aquí
        </Button>
      </div>
    </div>
  );
}
