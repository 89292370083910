export default function Card({
  title,
  text,
  img,
  options,
  index,
}: {
  title: string;
  text: string;
  img: string;
  options?: Array<any>;
  index: number
}) {
  return (
    <div className={`flex flex-row gap-5 ${index % 2 === 0 ? "" : "md:mt-20"}`}>
      <div className="p-[30px] bg-[#FAFAFA] rounded-md flex items-center shadow-md h-fit min-w-[100px]">
        <img className="w-full" src={img} alt={title}></img>
      </div>
      <div className="mt-10">
        <h2 className="text-2xl font-bold">{title}</h2>
        <p>{text}</p>
        <ul className="list-disc ml-5">
          {
            options?.map((option)=>{
              return (
                <li><span className="font-semibold">{option.title}</span> {option.text}</li>
              )
            })
          }
          
        </ul>
      </div>
    </div>
  );
}
