import goLogo from "assets/logo_go.svg";
import { Button } from "components/ui/button";

import fast from "assets/icons/fast.svg";
import shield from "assets/icons/shield.svg";
import tracking from "assets/icons/tracking.svg";

export default function GoSection() {
  return (
    <div className="px-[5%] my-10 max-w-[1700px] sm:mx-auto">
      <div className="flex justify-center mb-6 sm:mb-12 sm:mt-6">
        <img
          className="min-w-48 w-[25%] max-w-[300px]"
          src={goLogo}
          alt="logo Go"
        />
      </div>
      <div className="flex flex-col items-center sm:justify-center sm:gap-6 sm:flex-row sm:pr-[5%] flex-wrap">
        <div className="flex sm:flex-wrap gap-10 sm:w-[45%] justify-center">
          <div className="p-[15px] sm:p-[30px] w-[10%] bg-[#FAFAFA] rounded-md flex items-center shadow-md h-fit min-w-[80px] sm:min-w-[100px]">
            <img className="w-full" src={fast} alt={"icono 1"}></img>
          </div>
          <div className="p-[15px] sm:p-[30px] w-[10%] bg-[#FAFAFA] rounded-md flex items-center shadow-md h-fit min-w-[80px] sm:min-w-[100px]">
            <img className="w-full" src={shield} alt={"icono 2"}></img>
          </div>
          <div className="p-[15px] sm:p-[30px] w-[10%] bg-[#FAFAFA] rounded-md flex items-center shadow-md h-fit min-w-[80px] sm:min-w-[100px]">
            <img className="w-full" src={tracking} alt={"icono 3"}></img>
          </div>
        </div>
        <div className="items-center mt-8 sm:w-[50%] flex flex-col gap-3 text-center sm:items-start">
          <h2 className="text-xl font-bold sm:text-left">
            Barlleno Go: Tecnología que impulsa tus entregas
          </h2>
          <h3 className="text-xl font-medium">
            "Entregas eficientes, clientes satisfechos"
          </h3>
          <p className="sm:text-left">
            <span className="font-bold">Barlleno Go</span> es una aplicación especializada diseñada
            para conductores que buscan optimizar sus entregas y ofrecer un
            servicio de alta calidad. La app permite visualizar en tiempo real
            la ubicación de los pedidos, facilitando la planificación de rutas
            eficientes y asegurando entregas puntuales.
          </p>
          <Button className="w-full text-white sm:w-min">Conocer Más</Button>
        </div>
      </div>
    </div>
  );
}
