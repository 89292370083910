import phones from "assets/phones.webp";
import { Button } from "components/ui/button";

export default function AppDetails() {
  return (
    <div className="flex flex-col sm:flex-row relative py-12 bg-[#FAFAFA] sm:mb-32 mt-32 md:px-[5%]">
      <div className="absolute max-h-4 w-[calc(100%-2rem)] -top-20 sm:-top-14 sm:left-6 md:left-[10%] text-center sm:w-2/5">
        <img
          className="w-full h-auto max-h-[760px] sm:h-[550px] mx-auto xl:max-h-[480px] object-contain"
          src={phones} alt="Barlleno App"></img>
      </div>
      <div className="px-[5%] xs:mt-[130%] 465px:mt-[600px] sm:mt-0 sm:ml-[45%] [&>p]:pt-6 w-full sm:w-3/5 sm:pr-[5%]">
        <h2 className="text-2xl font-bold">
          Barlleno APP, Tus bebidas a domicilio
        </h2>
        <h3 className="text-xl font-medium">
          Brinda sin límites, Barlleno lo hace posible
        </h3>
        <p>
          Barlleno es la solución perfecta para disfrutar de una amplia variedad
          de bebidas, alcohólicas, no alcohólicas y más, con comodidad. Con
          horarios extendidos y entregas rápidas, garantiza bebidas frías y
          listas para disfrutar, transformando cada ocasión en una celebración.
        </p>
        <Button className="text-white mt-7 w-full sm:w-max">
          Conocer la App
        </Button>
      </div>
    </div>
  );
}
