import logo from "assets/logo_backbar.svg";
import { Button } from "components/ui/button";

export default function OnTrade() {
  return (
    <div className="bg-[#FAFAFA] py-10">
      <div className="flex justify-center mb-6">
        <img className="w-[200px]" src={logo} alt="onTrade Logo"></img>
      </div>
      <div className="text-center">
        <div className="w-full text-center px-[5%] md:px-[20%]">
          <h2 className="text-2xl font-bold">Backbar: Barlleno For Business</h2>
          <h3 className="text-xl font-medium">
            "Simplifica tu suministro, optimiza tu negocio."
          </h3>
          <p>
            Backbar ofrece una solución integral para el abastecimiento B2B de
            bebidas, centralizando todos los proveedores en una sola plataforma.
            Con Backbar, las empresas gestionan pedidos, acceden a un catálogo
            completo, automatizan procesos y realizan pagos en línea, todo desde
            un único lugar.
          </p>
        </div>
        <div className="flex justify-between px-[10%] gap-8 mt-6 max-w-[1500px] md:mx-auto snap-x overflow-auto md:overflow-hidden">
          <div className="min-w-[200px] w-full md:w-1/3 items-center text-center snap-start">
            <div className="flex justify-center">
              <img
                className="w-full md:w-3/5 lg:w-[60%] lg:  max-w-[400px] "
                src={require("assets/ontrade/Rectangle 189.png")}
                alt=""
              />
            </div>
            <div className="w-full  flex flex-col justify-between">
              <h3 className="text-xl font-semibold">
                Centraliza proveedores en una sola app.
              </h3>
              <p>
                Reúne a todos tus proveedores en una plataforma única.
                Simplifica la gestión y coordinación del abastecimiento.
              </p>
            </div>
          </div>
          <div className="min-w-[200px] w-full md:w-1/3 items-center text-center snap-start">
            <div className="flex justify-center">
              <img
                className="w-full md:w-3/5 lg:w-[60%] lg:max-w-[400px]"
                src={require("assets/ontrade/Rectangle 189.png")}
                alt=""
              />
            </div>
            <div className="w-full ">
              <h3 className="text-xl font-semibold">
                Catálogo completo y pedidos automatizados.
              </h3>
              <p>
                Accede a un catálogo extenso de productos. Automatiza el proceso
                de pedidos para mayor eficiencia.
              </p>
            </div>
          </div>
          <div className="min-w-[200px] w-full md:w-1/3 items-center text-center snap-start">
            <div className="flex justify-center">
              <img
                className="w-full md:w-3/5 lg:w-[60%] lg:max-w-[400px]"
                src={require("assets/ontrade/Rectangle 189.png")}
                alt=""
              />
            </div>
            <div className="w-full ">
              <h3 className="text-xl font-semibold">
                Pagos y facturación en línea
              </h3>
              <p>
                Realiza pagos y recibe facturación automáticamente desde la
                misma plataforma. Reduce la carga administrativa y agiliza la
                gestión financiera.
              </p>
            </div>
          </div>
        </div>

        <div className="mx-[5%]">
          <Button className="text-white mt-10 w-full sm:w-max">
            Lorem ipsum dolor
          </Button>
        </div>
      </div>
    </div>
  );
}
