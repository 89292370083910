import { useEffect, useState } from "react";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  type CarouselApi,
} from "components/ui/carousel";
import Autoplay from "embla-carousel-autoplay";
import BlogCard from "components/blogCard/blogCard";

const defaultInfo = [
  {
    title: "Tragos ipsum dolor sit amet consectetur.",
    description:
      "Lorem ipsum dolor sit amet consectetur. Sit pellentesque turpis fermentum non. Sem porta bibendum leo sit tortor porttitor. Lorem sem diam faucibus lacus vel lorem.",
    img: "example1.png",
    date: "Mar 01, 2024",
    location: "Barlleno",
  },
  {
    title: "Reseña Molotov en concierto",
    description:
      "Lorem ipsum dolor sit amet consectetur. Sit pellentesque turpis fermentum non. Sem porta bibendum leo sit tortor porttitor. Lorem sem diam faucibus lacus vel lorem.",
    img: "example2.png",
    date: "Mar 01, 2024",
    location: "Barlleno",
  },
  {
    title: "Barlleno promo Bac 40% de descuento",
    description:
      "Lorem ipsum dolor sit amet consectetur. Sit pellentesque turpis fermentum non. Sem porta bibendum leo sit tortor porttitor. Lorem sem diam faucibus lacus vel lorem.",
    img: "example3.png",
    date: "Mar 01, 2024",
    location: "Barlleno",
  },
  {
    title: "Tragos ipsum dolor sit amet consectetur.",
    description:
      "Lorem ipsum dolor sit amet consectetur. Sit pellentesque turpis fermentum non. Sem porta bibendum leo sit tortor porttitor. Lorem sem diam faucibus lacus vel lorem.",
    img: "example1.png",
    date: "Mar 01, 2024",
    location: "Barlleno",
  },
  {
    title: "Reseña Molotov en concierto",
    description:
      "Lorem ipsum dolor sit amet consectetur. Sit pellentesque turpis fermentum non. Sem porta bibendum leo sit tortor porttitor. Lorem sem diam faucibus lacus vel lorem.",
    img: "example2.png",
    date: "Mar 01, 2024",
    location: "Barlleno",
  },
  {
    title: "Barlleno promo Bac 40% de descuento",
    description:
      "Lorem ipsum dolor sit amet consectetur. Sit pellentesque turpis fermentum non. Sem porta bibendum leo sit tortor porttitor. Lorem sem diam faucibus lacus vel lorem.",
    img: "example3.png",
    date: "Mar 01, 2024",
    location: "Barlleno",
  },
];

export default function BlogSection() {
  const [api, setApi] = useState<CarouselApi>();
  const [current, setCurrent] = useState(0);

  useEffect(() => {
    if (!api) {
      return;
    }

    setCurrent(api.selectedScrollSnap() + 1);

    api.on("select", () => {
      setCurrent(api.selectedScrollSnap() + 1);
    });
  }, [api]);
  return (
    <div className="bg-[#FAFAFA] py-10 px-[10px] sm:px-[10%] ">
      <div className="px-[1%] w-full sm:w-[100%] lg:w-[60%] ml-1 max-w-[1220px] xl:mx-auto xl:text-left xl:w-full">
        <h2 className="text-xl font-bold">Barlleno Blog</h2>
        <p className="my-4">
          Bienvenidos a nuestro blog de Barlleno, donde exploramos las últimas
          tendencias y novedades en el apasionante mundo de la coctelería, así
          como datos importantes relacionados con la tecnología que están
          transformando la forma en que disfrutamos de nuestras bebidas
          favoritas.
        </p>
      </div>

      <Carousel
        setApi={setApi}
        className="w-[100%] sm:w-[100%] max-w-[1200px] mx-auto"
        opts={{ align: "start" }}
        plugins={[
          Autoplay({
            delay: 8000,
          }),
        ]}>
        <CarouselContent>
          {defaultInfo.map((info, index) => (
            <CarouselItem
              className="ml-5 sm:basis-1/2 sm:ml-0 lg:basis-1/3 mb-1"
              key={index}>
              <BlogCard article={info}></BlogCard>
            </CarouselItem>
          ))}
        </CarouselContent>
        {/* <CarouselPrevious className="border-none" />
        <CarouselNext className="border-none" /> */}
      </Carousel>
    </div>
  );
}
