import { Button } from "../ui/button";

export default function BlogCard({ article }: any) {
  return (
    <div className="mx-auto w-[90%] rounded-md p-3 flex flex-col bg-white h-full justify-between shadow-md md:w-[calc(100%_-_20px)]">
      <div>
        <div className="flex rounded-m mb-3">
          <img
            width={"100%"}
            className="w-full rounded-md object-cover"
            src={require("assets/examples/" + article.img)}></img>
        </div>
        <div className="flex flex-col gap-2">
          <p className="font-bold text-sm">
            {article.date} • {article.location}
          </p>
          <h3 className="font-semibold text-xl">{article.title}</h3>
          <p>{article.description}</p>
        </div>
      </div>
      <Button className="text-white mt-4">Leer artículo</Button>
    </div>
  );
}
