import { Button } from "components/ui/button";

export default function BarllenoEventSection() {
  return (
    <div className="flex flex-col md:flex-row mx-[5%] gap-10 my-10 max-w-[1300px] lg:w-[90%] lg:mx-auto">
      <div className="relative [&>img]:absolute w-full md:w-3/5 md:max-w-[500px]">
        <img
          className="w-[60%] lg:max-h-[150px] sm:max-h-[220px] -top-4  md:top-0 md:w-[280px] lg:h-[60%]"
          src={require("assets/event1.png")}></img>
        <img
          className="w-[60%] lg:max-h-[150px] lg:top-24 lg:left-[35%] sm:max-h-[220px]  -right-0 top-16 md:top-[20%] md:left-[40%] md:w-[280px]"
          src={require("assets/event2.png")}></img>
        <img
          className="w-[60%] xs:top-[170px] lg:max-h-[150px] lg:top-[200px] sm:max-h-[220px]  465px:top-52 md:top-[40%] md:left-[10%] md:w-[280px]"
          src={require("assets/event3.png")}></img>
      </div>
      <div className="xs:mt-[300px] 465px:mt-[400px] mb-3 sm:mb-20 lg:mb-36  md:mt-0 w-full md:w-2/5 lg:w-[80%] [&>p]:mt-6">
        <h2 className="text-2xl font-bold">
          Barlleno Events: Mixology Experts
        </h2>
        <h3 className="text-xl font-medium">
          "Donde cada sorbo cuenta una historia"
        </h3>
        <p>
          Barlleno Eventos se especializa en la creación de experiencias únicas
          para todo tipo de celebraciones. Se encargan de cada detalle, desde la
          selección de licores premium hasta la presentación impecable de sus
          barras. Sus mixólogos y bartenders profesionales garantizan un
          servicio de primer nivel, aportando elegancia y sofisticación a cada
          evento.
        </p>
        <Button className="text-white px-10 mt-7 w-full md:w-max">
          Conocer Más
        </Button>
      </div>
    </div>
  );
}
