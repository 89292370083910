import { Mail, MapPin, Phone } from "lucide-react";
import logo from "assets/logo.svg";
import mastercard from "assets/icons/mastercard.svg";
import visa from "assets/icons/visa.svg";
import amex from "assets/icons/amex.svg";
import instagram from "assets/icons/ig.png";
import facebook from "assets/icons/fb.svg";

export default function Footer() {
  return (
    <div className="py-12">
      <div className="flex flex-col-reverse sm:flex-row px-[5%] gap-8 items-center max-w-[1500px] md:mx-auto">
        <div>
          <img className="min-h-[70px]" src={logo} alt="Barlleno"></img>
          <div className="flex gap-4 sm:[&>div]:justify-center sm:[&>div]:flex-wrap sm:gap-4 [&>div]:flex [&>div]:gap-[10px] mt-4 justify-center md:[&>div]:gap-[6px] items-center">
            <div>
              <img className="w-10 sm:w-6" src={visa}></img>
              <img className="w-10 sm:w-6" src={mastercard}></img>
              <img className="w-10 sm:w-6" src={amex}></img>
            </div>
            <div>|</div>
            <div>
              <img className="w-8 sm:w-4" src={instagram}></img>
              <img className="w-8 sm:w-4" src={facebook}></img>
            </div>
          </div>
        </div>
        <div style={{ color: "#112A56" }} className="grid sm:grid-cols-4 gap-4 grid-cols-2">
          <div className="[&>div]:flex [&>div]:gap-2 [&>div]:text-xs flex flex-col gap-[6px] [&>div]:text-[#112A56]">
            <p className="font-semibold text-[#112A56]">El Salvador</p>
            <div className="text-[#112A56]">
              <MapPin size={15} className="min-w-[15px]" color="#112A56"></MapPin>
              <p className="text-[#112A56]">79 Av. Sur, pasaje A #222 Colonia San Benito, El Salvador</p>
            </div>
            <div className="text-[#112A56]">
              <Mail size={15} className="min-w-[15px]" color="#112A56"></Mail>
              <p className="text-[#112A56] break-all">contacto@barlleno.app</p>
            </div>
            <div className="text-[#112A56]">
              <Phone size={15} className="min-w-[15px]" color="#112A56"></Phone>
              <p className="text-[#112A56]">+503 21180405</p>
            </div>
          </div>
          <div className="[&>div]:flex [&>div]:gap-2 [&>div]:text-xs flex flex-col gap-[6px]">
            <p className="font-semibold text-[#112A56]">Guatemala</p>
            <div>
              <MapPin size={15} className="min-w-[15px]" color="#112A56"></MapPin>
              <p className="text-[#112A56]">13 calle 4-60 zona 10 Ciudad de Guatemala</p>
            </div>
            <div>
              <Mail size={15} className="min-w-[15px]" color="#112A56"></Mail>
              <p className="text-[#112A56] break-all">contacto.gt@barlleno.app</p>
            </div>
            <div>
              <Phone size={15} className="min-w-[15px]" color="#112A56"></Phone>
              <p className="text-[#112A56]">+502 41658892</p>
            </div>
          </div>
          <div className="[&>div]:flex [&>div]:gap-2 [&>div]:text-xs flex flex-col gap-[6px]">
            <p className="font-semibold text-[#112A56]">Colombia</p>
            <div>
              <MapPin size={15} className="min-w-[15px]" color="#112A56"></MapPin>
              <p className="text-[#112A56]">Cra 13 no 90-17 Bodega l, Colombia</p>
            </div>
            <div>
              <Mail size={15} className="min-w-[15px]" color="#112A56"></Mail>
              <p className="text-[#112A56] break-all">contacto.bta@barlleno.app</p>
            </div>
            <div>
              <Phone size={15} className="min-w-[15px]" color="#112A56"></Phone>
              <p className="text-[#112A56]">+57 310 2769 987</p>
            </div>
          </div>
          <div className="[&>div]:flex [&>div]:gap-2 [&>div]:text-xs flex flex-col gap-[6px]">
            <p className="font-semibold text-[#112A56]">Rep. Dominicana</p>
            <div>
              <MapPin size={15} className="min-w-[15px]" color="#112A56"></MapPin>
              <p className="text-[#112A56]">Erick Leonard Eckman No. 15, República Dominicana</p>
            </div>
            <div>
              <Mail size={15} className="min-w-[15px]" color="#112A56"></Mail>
              <p className="text-[#112A56] break-all">contacto.rd@barlleno.app</p>
            </div>
            <div>
              <Phone size={15} className="min-w-[15px]" color="#112A56"></Phone>
              <p className="text-[#112A56]">+1{"(809)"} 770 3850</p>
            </div>
          </div>
        </div>
      </div>
      <div className="h-[1px] bg-[#9DA1A7] my-5 w-[95%] mx-auto"></div>
      <div className="text-center">
        <p className="text-[#112A56]">
          Copyright © {new Date().getFullYear()} All rights reserved |{" "}
          <a href="https://premte.com/" className="underline text-primary">
            Premier Tec
          </a>
        </p>
      </div>
    </div>
  );
}
